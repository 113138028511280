/*==========================
Sidebar navigation
========================*/
#comminiNav {
    position: relative;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
}
.navbar {
    right: 0;
}
.game_menue_header {
    position: relative;
    right: 2px;
    z-index: 5500;
    text-align: center;
}
.game_menue_header #navbar {
    z-index: 1;
    height: 0;
}
.game_menue_header span {
    background: #ff7600;
    padding: 1px 12px;
    margin: 4px auto;
    min-width: 120px;
    display: block;
    max-width: 160px;
    border-radius: 20px;
}
button {
    cursor: pointer;
    background: transparent;;
    border: none;
    font-size: 1rem;
}
.navbar .toggle {
    position: absolute;
    right: 30px;
    top: 4px;
    cursor: pointer;
    background: transparent;;
    border: none;
    z-index: 10;
}
.menu-nav {
    list-style: none;
    position: absolute;
    background: transparent;
    right: -5px;
    width: 0;
    overflow: hidden;
    max-width: 150px;
    min-width: 140px;
    z-index: 9;
    font-size: 18px;
    box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
    transform: translateX(200px);
    transition: transform ease-in-out 0ms;
    top: 10px;
    padding-inline-start: 20px;
}
.menu-nav.show-menu {
    width: 100%;
    transform: translateX(0);
    position: relative;
}
.menu-nav li span {
    display: block;
    padding: 1rem;
    cursor: pointer;
}
.menu-nav li span:hover {
    background: #4c72ab66;
    border-radius: 12px;
    font-weight: bold;
}

.log-in {
    color: #777;
    border-top: 1px solid #efecec;
    margin-top: 15px;
    font-size: 15px;
}
/* logout */
.logout {
    position: absolute;
    right: 30px;
    top: 40px;
    display: flex;
    gap: 2rem;
    font-size: 20px;
    color: #8a4baf;
}
.chat_header svg {
    z-index: 2;
    position: absolute;
    right: 4px;
    top: 1px;
    fill: var(--main-font-bold-color);
    cursor: pointer;
}
.closeUserlist-nav.show-closeUserlist-none {
    display: none;
}

@media (max-width: 800px) {
    .closeUserlist-nav {
        right: calc(50% - 28px);
    }
}

@media (max-width: 1023px) and (min-width: 801px) {
    .closeUserlist-nav {
        right: 50px;
    }
}

@media (max-width: 1023px) {
    .closeUserlist-nav .show-closeUserlist-show {
        display: block;
    }
}

@media (min-width: 1024px) {
    .closeUserlist-nav .show-closeUserlist {
        display: none;
    }
    .menu-nav .userlist {
        display: none;
    }
}