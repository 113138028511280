
#friendsOverview .message__chats {
    position: relative;
    display: flex;
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;
}
.user_ico {
    position: relative;
    top: 0px;
    width: 50px;
    height: 50px;
    z-index: 101;
}
.Profilbild_ico {
    float: left;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 25px;
}
.user_info {
    position: relative;
    top: 5px;
    left: -15px;
    background: #00000070;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 40px;
    padding-left: 25px;
    width: calc(100% - 70px);
}
.user_info span {
    float: right;
    margin-right: 15px;
}