#lobby_messenge {
    position: absolute;
    bottom: 2px;
    left: 0;
    width: auto;
    height: 60px;
    right: 10px;
}
#mess {
    width: calc(100% - 70px);
    height: 35px;
    font-family: Verdana,Geneva,Arial,Helvetica,sans-serif;
    font-size: 12px;
    text-align: left;
    color: #FFF;
    background-color: #1C1C1C;
    border-radius: 15px 15px 0;
    padding: 6px;
}
#sending {
    color: black;
    height: 40px;
    font-size: 45px;
    cursor: pointer;
    position: absolute;
    top: -8px;
    width: 45px;
    right: 7px;
    bottom: 0;
}