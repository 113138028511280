#lobbyContent {
    position: absolute;
    inset: 0 2px 70px;
    border: #0a0b89 1px solid;
    border-radius: 18px;
    padding: 4px;
    background-color: var(--main-bg-color);
}
#lobby {
    width: 100%;
    font-size: 1rem;
}
#lobby h1 {
    font-size: 0.9em;
    color: #cfad5c;
    border-bottom: 1px solid #cc6600;
    margin: 1px 1px 1px 0;
    padding: 3px 3px 3px 3px;
}
#lobby span {
    font-size: 0.8em;
    color: #987e41;
    float: right;
}
#lobby p {
    color: #0C0C0C;
    margin: 2px 0;
    font-size: 0.9em;
    padding: 1px 3px 1px 3px;
    white-space: pre-line;
}
.typing__status {
    position: absolute;
    bottom: -10px;
    left: 10px;
    right: 10px;
    text-align: center;
}