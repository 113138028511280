#lobby_users {
    position: absolute;
    inset: 0 2px 2px auto;
    border: #a20b0b 1px solid;
    border-radius: 18px;
    padding: 4px;
    width: 150px;
    background-color: var(--main-bg-color);
    z-index: 1;
}

#lobby_users_list {
    font: normal 0.7em Verdana, Arial, sans-serif;
    width: 100%;
    color: black;
}
#lobby_users_list div {
    position: relative;
    border: 1px solid #0000004d;
    margin: 6px;
    border-radius: 4px;
    padding: 2px;
}
#lobby_users_list p {
    margin: 0 0 0 20px;
}
.users_list {
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    z-index: 1;
}
.user-nav.show-user {
    display: block;
}
.user-nav.show-user-none {
    display: none;
}
.createQuery {
    cursor: pointer;
}
.userRight_1 {
    color: #ff0000;;
}
.userRight_2 {
    color: #e78970;
}
.userRight_3 {
    color: #ffd41e;
}
.userRight_4 {
    color: #3740dc;
}