.Loaderunsichbar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 999;
  display:none;
}

.Loader {
  clear: both;
  position: absolute;
  left: 50%;
  width: 115px;
  z-index: 10000;
  padding: 5px;
  border: 1px solid #999;
  text-align: center;
  margin-top: -50px;
  background: url(https://static.dark-nights.net/img/games/dc/loader.gif) no-repeat;
  height: 115px;
  margin-left: -57.5px;
  font-size: 1rem;
  line-height: 1rem;
  top: calc(50% - 142px);
}

#MessageResponse {
  display:none;
  height:120px;
  width:300px;
  background-color: #3c3c3ccc;
  z-index: 10201;
  border: 1px solid orange;
  border-radius: 8px;
  color: white;
  position: absolute;
  left: 50%;
  margin: 0 0 0 -160px;
  padding: 10px;
}
.chat_header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 25px;
  z-index: 3;
  text-align: center;
}
.chat_header span {
  background: #ff7600;
  padding: 1px 12px;
  margin: 4px auto;
  min-width: 120px;
  display: block;
  max-width: 160px;
  border-radius: 20px;
}

.chat_body {
  position: absolute;
  inset: 30px 0 0 0;
  z-index: 2;
}
.users_list.on {
  background: #05fd05;
}
.users_list.afk {
  background: #ff5900;
}
.users_list.off {
  background: #ff0000;
}

.log-in {
  color: #777;
  border-top: 1px solid #efecec;
  margin-top: 15px;
  font-size: 15px;
}
/* logout */
.logout {
  position: absolute;
  right: 30px;
  top: 40px;
  display: flex;
  gap: 2rem;
  font-size: 20px;
  color: #8a4baf;
}
.closeUserlist-nav {
  z-index: 2;
  position: absolute;
  right: 4px;
}
.closeUserlist-nav.show-closeUserlist-none {
  display: none;
}

.unread_messages span {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  0%   {background-color: #ff7600;}
  25%  {background-color: #ff6200;}
  50%  {background-color: #ff2f00;}
  75%  {background-color: #ff6200;}
  100% {background-color: #ff7600;}
}

@media (max-width: 800px) {
  .closeUserlist-nav {
    right: calc(50% - 28px);
  }
}

@media (max-width: 1023px) and (min-width: 801px) {
  .closeUserlist-nav {
    right: 50px;
  }
}

@media (max-width: 1023px) {
  .closeUserlist-nav .show-closeUserlist-show {
    display: block;
  }
}

@media (min-width: 1024px) {
  .closeUserlist-nav .show-closeUserlist {
    display: none;
  }
  .menu-nav .userlist {
    display: none;
  }
}