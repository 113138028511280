#Igm_Content {
    position: absolute;
    inset: 0;
    width: auto;
    overflow: hidden;
    background-color: var(--main-bg-dark-color);
}
#igmOverview {
    border: 1px solid #0a0b89;
    border-radius: 18px;
    inset: 25px 0 0 0;
    padding: 4px;
    position: absolute;
}
#igmOverview .message__chats {
    position: relative;
    display: flex;
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;
}
#igmOverview .unraed {
    font-weight: bold;
    color: var(--main-font-bold-color);
}
.user_ico {
    position: relative;
    top: 0;
    width: 50px;
    height: 50px;
    z-index: 101;
}
.Profilbild_ico {
    float: left;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 25px;
}
.user_info {
    position: relative;
    top: 5px;
    left: -15px;
    background: #00000070;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 40px;
    padding-left: 25px;
    width: calc(100% - 70px);
}
.user_info span {
    float: right;
    margin-right: 15px;
}