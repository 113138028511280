#chatComm {
    position: relative;
    inset: 1px 0 0 6px;
    height: 40px;
    width: 32px;
    cursor: pointer;
}

#chatComm img {
    position: relative;
    height: 100%;
    width: 100%;
    inset: 0;
}

#chatUnread {
    position: absolute;
    right: -10px;
    top: -10px;
    background: var(--main-bg-color);
    color: var(--main-font-color);
    border-radius: 50%;
    width: 18px;
    text-align: center;
}