#friends_Content {
    position: absolute;
    inset: 0;
    width: auto;
    overflow: hidden;
}
#friendsOverview {
    border: 1px solid #0a0b89;
    border-radius: 18px;
    inset: 0;
    padding: 4px;
    position: absolute;
    background-color: var(--main-bg-dark-color);
}