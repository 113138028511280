.user_saerch_content {
    position: absolute;
    inset: auto 0;
    text-align: center;
}
.user_saerch_content input {

}
.list_content {
    position: relative;
    z-index: 1;
    width: 200px;
    inset: 0 0 0 calc(50% - 100px);
    background-color: var(--main-bg-color);
    border-radius: 8px;
    color: var(--main-font-color);
    padding: 1px 0;
}
.list_content span {
    cursor: pointer;
}